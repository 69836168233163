import '@fontsource-variable/open-sans';
import '@fontsource-variable/spline-sans';
import './bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import '../css/app.css';

import React from 'react';

import {
  faAlarmClock,
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBookOpen,
  faBuildingColumns,
  faChartColumn,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck as farCircleCheck,
  faCog,
  faCopy,
  faCreditCard,
  faDollarSign,
  faDownload,
  faEllipsis,
  faEllipsisVertical,
  faExclamationCircle,
  faFileInvoice,
  faFileInvoiceDollar,
  faGear,
  faMagnifyingGlass,
  faPaperPlane,
  faPlus,
  faRotateLeft,
  faSpinner,
  faStore,
  faTriangleExclamation,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBriefcase as fasBriefcase,
  faCircleCheck as fasCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { createInertiaApp } from '@inertiajs/react';
import * as Sentry from '@sentry/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';

import { AnalyticsProvider } from '@/Analytics/AnalyticsProvider';

const ToastContainer = React.lazy(() =>
  import('react-toastify').then((module) => ({
    default: module.ToastContainer,
  }))
);

const loadFontAwesomeIcons = () => {
  return import('@fortawesome/fontawesome-svg-core').then(({ library }) => {
    library.add(
      faArrowRightFromBracket,
      faAlarmClock,
      faBars,
      faBell,
      faBookOpen,
      fasBriefcase,
      faBuildingColumns,
      faChartColumn,
      faCheck,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      fasCircleCheck,
      farCircleCheck,
      faCog,
      faCopy,
      faCreditCard,
      faDollarSign,
      faDownload,
      faEllipsisVertical,
      faEllipsis,
      faExclamationCircle,
      faFileInvoice,
      faFileInvoiceDollar,
      faGear,
      faMagnifyingGlass,
      faPaperPlane,
      faPlus,
      faRotateLeft,
      faTriangleExclamation,
      faSpinner,
      faStore,
      faUser,
      faUsers,
      faXmark
    );
  });
};

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import.meta.glob(['../fonts/**']);

createInertiaApp({
  title: (title) => `${appName} - ${title}`,
  resolve: async (name) => {
    const parts = name.split('::');

    if (parts.length > 1) {
      const modules = import.meta.glob(
        '../../modules/**/resources/js/**/*.tsx'
      );
      const modulePath: string[] = Object.keys(modules);
      const modulePages: { [key: string]: string } = {};
      modulePath.map((path: string): void => {
        const pathParts: string[] = path
          .replace('../../modules/', '')
          .split('/');
        const moduleName: string = pathParts.shift() as string;
        // @ts-ignore
        const pageName: string = pathParts
          .slice(2)
          .join('/')
          .replace('.tsx', '');
        const key: string = `${moduleName}::${pageName}`;
        modulePages[key] = path;
      });

      if (Object.keys(modulePages).includes(name)) {
        const path = modulePages[name];
        return await resolvePageComponent(
          path,
          import.meta.glob([
            './**/*.tsx',
            '../../modules/**/resources/js/**/*.tsx',
          ])
        );
      }
    }

    return await resolvePageComponent(
      `./${name}.tsx`,
      import.meta.glob(['./**/*.tsx', '../../modules/**/resources/js/**/*.tsx'])
    );
  },
  setup({ el, App, props }) {
    loadFontAwesomeIcons();

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      integrations: [Sentry.replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0,
    });

    const root = createRoot(el);

    root.render(
      <React.StrictMode>
        <AnalyticsProvider>
          <App {...props} />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </AnalyticsProvider>
      </React.StrictMode>
    );
  },
  progress: {
    color: '#4B5563',
  },
});
